import React from "react";

import Layout from "../components/layout";
import AboutSection from "../components/about-section";

const AboutUsPage = () => (
    <Layout>
        <AboutSection />
    </Layout>
);

export default AboutUsPage;
